.c2a-container {
    background-color: #f5f5f5;
    padding: 40px;
    text-align: left;
  }
  
  .c2a-container h2 {
    /* font-size: 36px; */
    /* font-weight: bold; */
    margin-bottom: 20px;
    color: black;
  }
  .c2a-header-link {
      text-decoration: none;
      
  }
  .c2a-container p {
    font-size: 16px;
    color: black;
    margin-bottom: 40px;
  }
  
  .c2a-container .button {
    display: inline-block;
    background-color: #0b5394;
    color: #fff;
    padding: 12px 24px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.2s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }
  
  .c2a-container .button:hover {
    background-color: #0b5394;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }