/* .hero-section {
    position: relative;
    height: 500px;
    overflow: hidden;
    font-family: 'Amplitude','Arial Narrow',sans-serif;
  }
  
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 1;
  }
  
  .hero-content h1 {
    font-size: 48px;
    margin: 0;
  }
  
  .hero-content p {
    font-size: 24px;
    margin: 20px 0;
  }
  
  .button {
    display: inline-block;
    background-color: #0b5394;
    color: #fff;
    padding: 12px 24px;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.2s ease;
  }
  
  .button:hover {
    background-color: #0077c2;
    transform: scale(1.1);
  }
   

  .hero-button {
    display: inline-block;
    background-color: #0b5394;
    color: #fff;
    padding: 12px 24px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.2s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }
  
  .hero-button:hover {
    background-color: #0077c2;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  } */

  /* .hero-container {
    position: relative;
    overflow: hidden;
  }
  
  .hero-image {
    position: relative;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
  }
  
  .hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .hero-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .hero-content button:hover {
    background-color: #0062cc;
  }
   */
/* 
   .hero-container {
    position: relative;
    overflow: hidden;
  }
  
  .hero-image {
    position: relative;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .hero-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .hero-content button:hover {
    background-color: #0062cc;
  }
  
  @media only screen and (max-width: 768px) {
    .hero-content h1 {
      font-size: 2rem;
    }
  
    .hero-content p {
      font-size: 1rem;
    }
  
    .hero-content button {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
  }
   */

   .hero-container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  
  .hero-content h1 {
    font-size: 3rem;
    color: white;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 2rem;
  }
/*   
  .hero-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  } */
  

  .hero-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .hero-content button:hover {
    background-color: #0062cc;
  }
  
  @media only screen and (max-width: 768px) {
    .hero-content h1 {
      font-size: 2rem;
    }
  
    .hero-content p {
      font-size: 1rem;
    }
  
    .hero-content button {
      font-size: 1rem;
      padding: 1.0rem 1rem;
    }
  }
  