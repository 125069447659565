/* .App {
    text-align: center;
    font-family: sans-serif;
    padding: 50px;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 30px;
  } 
  
  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-section {
    width: 300px;
    margin: 30px;
    text-align: left;
  }
  
  .service-section img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .service-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .service-section p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .service-section button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .service-section button:hover {
    background-color: #0069d9;
  }
  */

  .services-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-section {
    width: 100%;
    max-width: 300px;
    margin: 30px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .service-section img {
    width: 100%;
    height: auto;
  }
  
  .service-section .content {
    padding: 20px
  }

  .service-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .service-section p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #666;
  }
  
  .service-section button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .service-section button:hover {
    background-color: #0069d9;
  }
  