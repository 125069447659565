/* .news-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0;
  }
  
  .column {
    flex-basis: calc(50% - 1rem);
  }
  
  .news-card {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }
  
  .news-card img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .news-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .news-card p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .news-card a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .news-card a:hover {
    text-decoration: underline;
  }
 */


  
  .news-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .news-card {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }

  .news-card a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .news-card a:hover {
    text-decoration: underline;
  }
   

  .service-section {
    width: 100%;
    max-width: 300px;
    margin: 30px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .news-card img {
    width: 100%;
    height: auto;
  }
  
  .service-section .content {
    padding: 20px
  }

  .service-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .service-section p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #666;
  }
  
  .service-section button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .service-section button:hover {
    background-color: #0069d9;
  }
  