.newsletter-block {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  
  .newsletter-block h2 {
    margin-top: 0;
  }
  
  .newsletter-block form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .newsletter-block label {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .newsletter-block input[type="email"] {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .newsletter-block button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .newsletter-block button[type="submit"]:hover {
    background-color: #0062cc;
  }
  