.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  flex: 1;
}

/* body {
  font-family: 'Optima', sans-serif;
} */

.footer {
  background-color: #f2f2f2;
  color: #666;
  text-align: center;
  padding: 20px;
  font-size: 14px;
}

main {
  max-width: 800px;
  margin: 0 auto;
  padding: 2em;
}

h1, h2, h3 {
  font-weight: bold;
  margin-top: 2em;
  margin-bottom: 1em;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

p {
  margin-bottom: 1.5em;
}