.article-page {
    margin: 2rem auto;
    max-width: 800px;
  }
  
  .article-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .article-header img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .article-header h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .article-header p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  
  .article-body {
    line-height: 1.5;
  }
  
  .article-body p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  