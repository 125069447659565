/* Annoucement container */
.ab-container {
    display: block;
    padding: 10px;
    background-color:#0b5394;
    
    
  }
  .ab-items {
    text-align: right;
    color: white;
    font-family: 'Amplitude','Arial Narrow',sans-serif;
  }

  .ab-item {
      text-decoration: none;
      color: white;
  }