.footer-container {
    display: flex;
    /* position: fixed;
    width: 100%;
    bottom: 0; */
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
    font-size: 14px;
    color: #666;
    border-top: 5px solid #d5d3d3;
  }

  .footer-social-bar {
      text-align: center;
      overflow: hidden;
      margin: 0;
  }


.footer-internal {
    padding: 0 40px;
    
    color: #5f5d5d;
    max-width: 1440px;
    margin: 0 auto;
  }

  .footer-social-follow {
    display: inline-block;
    background: #f1f0ef;
    font-family: "Font Awesome 5 Pro";
    font-size: 19px;
    color: #5f5d5d;
    text-align: center;
    overflow: hidden;
    padding: 0;
  }

  .footer-social-follow > li {
    display: inline-block;
    background-color: #f1f0ef;
    padding: 10px;
  }

  .footer-follow-link {
    margin-right: 1.2rem;
    margin-left: 1.2rem;
    
  }
  .footer-gray-social-link {
    color: #5f5d5d;
    text-decoration: none;
  }

  /* .footer-nav-list {
    font-family: 'Amplitude','Arial Narrow',sans-serif;
    display: flex;
    
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    background-color: #f2f2f2;
    
    font-size: 14px;
    color: #666;
  } */

  .footer-nav-list {
    display: grid;
    grid-template-columns: repeat(5, 2fr);
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    font-family: 'Amplitude','Arial Narrow',sans-serif;
    padding: 20px;
    background-color: #f2f2f2;
    font-size: 14px;
    color: #666;
  }
  
  
  .footer__column {
    flex: 1;
    
    /* max-width: 20%; */
  }
  
  .footer__heading {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  
  .footer__logo {
    margin-bottom: 10px;
    width: 150px;
  }
  

  .footer__list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .footer__list li {
    margin-bottom: 10px;
  }
  
  .footer__list a {
    color: #666;
    text-decoration: none;
  }
  
  .footer-container hr {
      border-top: 1px solid #d5d3d3;
  }

  .footer__legal__row {
      margin: 0 auto;
      display: flex;
      width: 100%;
  }

  .legal__links {
      text-decoration: none;
      color: #5f5d5d;
  }
.footer__copyright__legal {
margin-left: 20px;

}

  .footer__copyright {
      margin: 0;
      min-width: 100px;
      text-align: right;
      
  }

/* Responsive styles for tablet devices */
@media screen and (max-width: 768px) {
  .footer-container {
    flex-wrap: wrap;
  }

  .footer-nav-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__column {
    /* margin-right: 0; */
    /* margin-bottom: 20px; */
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .footer-internal {
    padding: 0 20px;
    
  }

  .footer-follow-link {
    margin-right: .8rem;
    margin-left: .8rem;
    
  }
  
  .footer__column {
    max-width: 100%;
    /* margin-bottom: 20px; */
    text-align: center;
  }
  
  .footer__heading { 
    font-size: 28px;
  }
  
  .footer__logo {
    width: 100px;
  }
  
  .footer__row {
    display: flex;
    
    /* justify-content: center; */
    
  }

  
  .footer__list li {
    margin-bottom: 5px;
  }
  
  .footer__list a {
    font-size: 16px;
  }

  .footer-nav-list {
    grid-template-columns: 1fr;
    gap: 0px;
    max-width: 100%;
  }
  
  .footer__legal__nav {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .footer__legal__nav a {
    font-size: 12px;
  }
  
  .footer__legal__nav hr {
    display: none;
  }
  
  .footer__legal__row {
    flex-direction: column;
    align-items: center;
  }
  
  .footer__social-follow {
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
  }
  
  .footer-social-follow > li {
    padding: 8px;
  }

  .footer__copyright {
    margin: 0;
    min-width: 150px;
    font-size: 12px;
    text-align: center;
    
}
}

