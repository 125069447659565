/* .about {
    display: flex;
    justify-content: left;
    margin-top: 2rem;
  }
  
  .about-content {
    max-width: 800px;
    padding: 1rem;
  }
  
  .about img {
    max-width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .about p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
   */
/* 
   .about {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .about-content {
    max-width: 800px;
    padding: 1rem;
  }
  
  .about-image {
    max-width: 50%;
    margin-left: 2rem;
  }
  
  .about img {
    max-width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .about p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  } */
  

  /* .about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .history-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .history-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .history-section p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .founder-photos {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .founder-photos img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 1rem;
    border: 2px solid #007bff;
  }
  
  .mission-section {
    text-align: center;
  }
  
  .mission-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .mission-section p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  @media only screen and (max-width: 768px) {
    .history-section p {
      font-size: 1rem;
    }
  
    .founder-photos img {
      width: 100px;
      height: 100px;
    }
  
    .mission-section p {
      font-size: 1rem;
    }
  } */
  .about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .founder-section {
    text-align: left;
    margin-bottom: 2rem;
  }
  
  .founder-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .founder-info {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 2rem;
  }
  
  .founder-photo img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
    border: 2px solid #007bff;
  }

  .founder-photo-1 img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 2%;
    margin-bottom: 1rem;
    /* border: 2px solid #007bff; */
  }
  
  .founder-text {
    font-size: 1.25rem;
    line-height: 1.5;
    text-align: left;
  }
  
  .founder-text p {
    margin-bottom: 1rem;
  }
  
  .founder-text a {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  .founder-text a:hover {
    background-color: #0062cc;
  }
  
  .mission-section {
    text-align: left;
  }
  
  .mission-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .mission-section p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  @media only screen and (max-width: 768px) {
    .founder-photo img {
      width: 200px;
      height: 200px;
    }
  
    .founder-text p {
      font-size: 1rem;
    }
  
    .founder-text a {
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
    }
  
    .mission-section p {
      font-size: 1rem;
    }
  }
  