/* Navigation container */
.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-family: 'Amplitude','Arial Narrow',sans-serif;
    
  }
  
  /* Logo */
  .nav-logo {
    font-size: 28px;
    font-weight: bold;
    width: 200px;
  }
  
  /* Navigation list */
  .nav-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
 /* Navigation toggle button */
.nav-toggle {
    display: none;
  }
  
  Navigation toggle label
  .nav-toggle-label {
    font-size: 24px;
    padding: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  /* Navigation item */
  .nav-item {
    margin-left: 20px;
  }
  
  /* Navigation link */
  .nav-link {
    text-decoration: none;
    font-size: 18px;
    color: #5f5d5d;
  }

  .nav-link:hover {
    text-decoration: none;
    color: #5f5d5d;
    border-bottom: 3px solid #1a7b99;
  }

  .nav-toggle:checked + .nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media screen and (max-width: 1024px) {
    /* Navigation toggle button */
    .nav-toggle {
      display: block;
    }
  
    /* Navigation list */
    .nav-list {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }
  
    /* Navigation item */
    .nav-item {
      display: block;
      margin: 10px 0;
    }
  
    /* Navigation link */
    .nav-link {
      font-size: 20px;
    }
  
    /* Show navigation list when toggle button is checked */
    .nav-toggle:checked + .nav-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  /* Responsive styles for mobile devices */
  @media screen and (max-width: 768px) {
    /* Logo */
    .nav-logo {
      font-size: 24px;
    }
  
    /* Navigation item */
    .nav-item {
      margin: 0 10px;
    }
  
    /* Navigation link */
    .nav-link {
      font-size: 16px;
    }
  }