/* .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  button {
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    border: none;
    background-color: #0077ff;
    color: #fff;
    font-size: 1.2rem;
    margin-top: 1.5rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  button:hover {
    background-color: #005bc1;
  }
   */

   .contact-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .form-wrapper {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 50px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #444444;
  }
  
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #444444;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    resize: none;
  }
  
  button {
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    border: none;
    background-color: #0077ff;
    color: #fff;
    font-size: 1.2rem;
    margin-top: 1.5rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  button:hover {
    background-color: #005bc1;
  }
  
  .contact-info {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    /* background-color: #0077ff; */
    background-color: #f2f2f2;
    border-radius: 5px;
    /* color: #fff; */
    color: #444444;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .contact-info h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .info-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .info-group h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .info-group p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  